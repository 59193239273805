<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="isEdit ? '修改运营商' : '运营商入驻'"
    width="800px"
    @closed="resetForm"
    :closeOnClickModal="false"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商名称" prop="name_operator">
              <el-input
                v-model.trim="ruleForm.name_operator"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="name_chief">
              <el-input
                v-model.trim="ruleForm.name_chief"
                maxlength="4"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="tel">
              <el-input v-model.trim="ruleForm.tel" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="!isEdit">
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户名" prop="user_name">
                <el-input
                  v-model.trim="ruleForm.user_name"
                  maxlength="24"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <!-- 编辑时 可选表单 -->
          <template v-if="isEdit">
            <el-col :span="12">
              <el-form-item label="重置密码" prop="_pwd">
                <el-input
                  type="password"
                  v-model.trim="ruleForm._pwd"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认密码" prop="_confirm_pwd">
                <el-input
                  type="password"
                  v-model.trim="ruleForm._confirm_pwd"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- 新增时 必选表单 -->
          <template v-else>
            <el-col :span="12">
              <el-form-item label="密码" prop="edit">
                <el-input
                  type="password"
                  v-model.trim="ruleForm.pwd"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认密码" prop="confirm_pwd">
                <el-input
                  type="password"
                  v-model.trim="ruleForm.confirm_pwd"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  check_name_operator,
  check_name_chief,
  check_tel,
  check_user_name,
  check_pwd,
  _check_pwd
} from '@/utils/validator.js'
import MD5 from 'md5'
import { AddOperatorApi, EditOperatorApi } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 编辑还是修改
      isEdit: false,
      // 表单
      ruleForm: {
        id_operator: '',
        name_operator: '',
        name_chief: '',
        tel: '',
        user_name: '',
        pwd: '',
        confirm_pwd: '',
        _pwd: '',
        _confirm_pwd: ''
      },
      // 规则
      rules: {
        name_operator: check_name_operator,
        name_chief: check_name_chief,
        tel: check_tel,
        user_name: check_user_name,
        pwd: check_pwd,
        confirm_pwd: check_pwd,
        _pwd: _check_pwd,
        _confirm_pwd: _check_pwd
      }
    }
  },
  methods: {
    // 添加账号
    add() {
      this.isEdit = false
      this.dialogVisible = true
    },

    // 编辑运营商
    edit(row) {
      this.id_operator = row.id_operator
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.name_operator = row.name_operator
        this.ruleForm.name_chief = row.name_chief
        this.ruleForm.tel = row.tel
      })
    },

    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },

    // 点击了确定
    confirm() {
      this.$refs.ruleForm.validate(async _ => {
        if (!_) return
        if (this.isEdit) {
          if (this.ruleForm._pwd !== this.ruleForm._confirm_pwd) {
            return this.$message.warning('两次输入的密码不一致')
          }
          this.confirmEdit()
        } else {
          if (this.ruleForm.pwd !== this.ruleForm.confirm_pwd) {
            return this.$message.warning('两次输入的密码不一致')
          }
          this.confirmAdd()
        }
      })
    },

    // 确定添加
    async confirmAdd() {
      const { name_operator, name_chief, tel, user_name, pwd } = this.ruleForm
      const params = {
        name_operator,
        name_chief,
        tel,
        user_name,
        pwd: MD5(pwd)
      }
      const { ret, data, msg } = await AddOperatorApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 确定修改
    async confirmEdit() {
      const params = {
        id_operator: this.id_operator,
        name_operator: this.ruleForm.name_operator,
        name_chief: this.ruleForm.name_chief,
        tel: this.ruleForm.tel
      }
      if (this.ruleForm._confirm_pwd) {
        params.pwd = MD5(this.ruleForm._pwd)
      }
      const { ret, data, msg } = await EditOperatorApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('修改成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 16px 36px;
}
</style>
