<template>
  <div class="operator">
    <!-- 运营商入驻按钮 -->
    <div class="operator_add">
      <el-button icon="el-icon-plus" type="primary" @click="addOperator"
        >运营商入驻</el-button
      >
    </div>
    <!-- 表格内容 -->
    <div class="table_box">
      <div class="title">
        <div></div>
        <span>运营商列表</span>
      </div>

      <div class="table_search">
        <el-input
          class="input w200"
          placeholder="输入运营商名称查询"
          v-model="name_operator"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-input
          class="input w200"
          placeholder="输入联系人或联系方式查询"
          v-model="content"
          prefix-icon="el-icon-search"
        ></el-input>

        <el-button icon="el-icon-search" type="success" @click="search"
          >查 询</el-button
        >
        <el-button icon="el-icon-refresh-left" type="info" @click="reset"
          >重 置</el-button
        >
      </div>

      <!-- 表格 -->
      <div class="table">
        <el-table
          :data="operatorList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column
            label="运营商"
            prop="name_operator"
          ></el-table-column>
          <el-table-column label="用户名">
            <template v-slot="scope">
              <span>{{ scope.row.admin_info.user_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系人" prop="name_chief"></el-table-column>
          <el-table-column label="联系方式" prop="tel"></el-table-column>
          <el-table-column
            label="入驻日期"
            prop="time_create"
          ></el-table-column>
          <el-table-column label="最后登录时间">
            <template v-slot="scope">
              <span>{{ scope.row.time_update || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <div class="operate">
                <img
                  @click="editOperator(scope.row)"
                  src="../../assets/table_edit.png"
                  alt=""
                  title="修改"
                />
                <img
                  @click="openDelDialog(scope.row)"
                  src="../../assets/table_delete.png"
                  alt=""
                  title="删除"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加 修改运营商 -->
    <ChangeOperator ref="changeOperatorRef" @refresh="search" />

    <!-- 消息弹窗 -->
    <Alert ref="alertRef" @confirm="delOperator" />
  </div>
</template>

<script>
import { GetOperatorListApi, DelOperatorApi } from '@/api'
import ChangeOperator from './component/change_operator.vue'
import Alert from '@/components/alert/alert.vue'

export default {
  name: 'operator',
  components: { ChangeOperator, Alert },
  data() {
    return {
      // 运营商名称
      name_operator: '',
      // 运营商联系人/联系方式
      content: '',
      // 运营商列表
      operatorList: [],
      total: 0,
      page_id: 0,
      page_num: 20,
      // 删除运营商缓存的数据
      delInfo: ''
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.search()
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.search()
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.name_operator.trim()) {
        params.name_operator = this.name_operator.trim()
      }
      if (this.content.trim()) {
        params.content = this.content.trim()
      }
      return params
    },

    // 查询
    search() {
      this.page_id = 0
      this.getOperatorList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.name_operator = this.content = ''
      this.getOperatorList()
    },

    // 获取运营商列表
    async getOperatorList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetOperatorListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.operatorList = data.data
    },

    // 添加运营商
    async addOperator() {
      this.$refs.changeOperatorRef.add()
    },

    // 编辑运营商
    editOperator(row) {
      this.$refs.changeOperatorRef.edit(row)
    },

    // 打开删除提醒弹窗
    openDelDialog(row) {
      this.delInfo = row
      this.$refs.alertRef.alert({
        title: '删除提醒',
        msg: '您确定要删除该运营商吗? 此操作不可恢复!'
      })
    },

    // 删除运营商
    async delOperator() {
      const { ret, data, msg } = await DelOperatorApi({
        id_operator: this.delInfo.id_operator
      })
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getOperatorList()
    }
  },
  created() {
    this.getOperatorList()
  }
}
</script>

<style lang="scss" scoped>
.operator {
  width: 100%;
  padding: 0 22px;
}

.operator_add {
  height: 64px;
  display: flex;
  align-items: center;
}

.table_box {
  background-color: #fff;
  min-height: calc(100vh - 194px);
  box-shadow: 0px 4px 10px 0px rgba(200, 215, 239, 0.3);
  border-radius: 4px;

  .title {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;

    div {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .table_search {
    display: flex;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 22px;

    .el-input {
      margin: 0 20px 0 0;
    }
  }

  .table {
    padding: 0 22px;

    .operate {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin: 0 11px;
        cursor: pointer;
      }
    }
  }
}
</style>
